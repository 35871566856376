import _export from "../internals/export";
import _getBuiltIn from "../internals/get-built-in";
import _hasOwnProperty from "../internals/has-own-property";
import _toString from "../internals/to-string";
import _shared from "../internals/shared";
import _nativeSymbolRegistry from "../internals/native-symbol-registry";
var $ = _export;
var getBuiltIn = _getBuiltIn;
var hasOwn = _hasOwnProperty;
var toString = _toString;
var shared = _shared;
var NATIVE_SYMBOL_REGISTRY = _nativeSymbolRegistry;
var StringToSymbolRegistry = shared("string-to-symbol-registry");
var SymbolToStringRegistry = shared("symbol-to-string-registry"); // `Symbol.for` method
// https://tc39.es/ecma262/#sec-symbol.for

$({
  target: "Symbol",
  stat: true,
  forced: !NATIVE_SYMBOL_REGISTRY
}, {
  "for": function (key) {
    var string = toString(key);
    if (hasOwn(StringToSymbolRegistry, string)) return StringToSymbolRegistry[string];
    var symbol = getBuiltIn("Symbol")(string);
    StringToSymbolRegistry[string] = symbol;
    SymbolToStringRegistry[symbol] = string;
    return symbol;
  }
});
export default {};